import { DocumentNode, FieldFunctionOptions, FieldPolicy, TypePolicies, gql } from '@apollo/client'

import { GetAllMarketProductsQuery, GetAllWinesQuery, MyShopMarketQuery } from '@hooks/api'
import { Config, ConfigPlugin } from '@lib/Config'

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}

export class ProductPlugin implements ConfigPlugin {

  config!: Config

  async configure(config: Config): Promise<void> {
    this.config = config
  }

  typePolicies = (): TypePolicies => ({
    Product: {
      fields: {
        quantityInCart: {
          read(currentValue: number, options: FieldFunctionOptions): number {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_PRODUCT_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const quantity = quantities?.[id]?.quantity as number || 0
              return quantity
            }
            return 0
          },
        },
        cartItemId: {
          read(currentValue: number, options: FieldFunctionOptions): number | null {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_PRODUCT_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const cartItemId = quantities?.[id]?.cartItemId || null
              return cartItemId
            }
            return null
          },
        },
      },
    },
    ProductGroup: {
      fields: {
        quantityInCart: {
          read(currentValue: number, options: FieldFunctionOptions): number {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_GROUP_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const quantity = quantities?.[id]?.quantity as number || 0
              return quantity
            }
            return 0
          },
        },
        cartItemIds: {
          read(currentValue: number, options: FieldFunctionOptions): string[] {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_GROUP_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const cartItemId = quantities?.[id]?.cartItemIds || []
              return cartItemId
            }
            return []
          },
        },
      },
    },
  })

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    allWines: {
      keyArgs: ['filters', 'order'],
      read(existing: GetAllWinesQuery['wines'], { args: { skip, limit } }) {
        if (existing) {
          return {
            ...existing,
            list: skip !== undefined && limit !== undefined ? existing.list.slice(0, skip + limit) : existing.list,
          }
        }
      },
      merge(existing: GetAllWinesQuery['wines'], incoming: GetAllWinesQuery['wines'], { args: { skip = 0 } }) {

        if (skip === undefined) {
          return incoming
        }

        const merged = existing ? existing.list.slice(0) : []
        for (let i = 0; i < incoming.list.length; ++i) {
          merged[skip + i] = incoming.list[i]
        }
        return {
          ...incoming,
          list: merged,
        }
      },
    },
    allMarketProducts: {
      keyArgs: ['filters', 'order'],
      read(existing: GetAllMarketProductsQuery['marketProducts'], { args: { skip, limit } }) {
        if (existing) {
          return {
            ...existing,
            list: existing.list.slice(0, skip + limit),
          }
        }
      },
      merge(existing: GetAllMarketProductsQuery['marketProducts'], incoming: GetAllMarketProductsQuery['marketProducts'], { args: { skip = 0 } }) {
        const merged = existing ? existing.list.slice(0) : []
        for (let i = 0; i < incoming.list.length; ++i) {
          merged[skip + i] = incoming.list[i]
        }
        return {
          ...incoming,
          list: merged,
        }
      },
    },
    myShopItems: {
      read(existing: MyShopMarketQuery['myShopItems'], { args: { skip, limit } }) {
        // console.log('myShopItems read existing => ', existing?.list?.length, ' skip => ', skip, ' limit => ', limit)
        if (existing) {
          return {
            ...existing,
            list: existing.list.slice(0, skip + limit),
          }
        }
      },
      merge(existing: MyShopMarketQuery['myShopItems'], incoming: MyShopMarketQuery['myShopItems'], { args: { skip = 0 } }) {
        const merged = existing ? existing.list.slice(0) : []
        for (let i = 0; i < incoming.list.length; ++i) {
          merged[skip + i] = incoming.list[i]
        }
        // console.log('myShopItems merge => ', merged?.length, ' existing => ', existing?.list?.length, ' skip => ', skip, ' incoming => ', incoming?.list?.length)
        return {
          ...incoming,
          list: merged,
        }
      },
    },
  })

  extensions = (): DocumentNode => {
    const products = this.config.possibleTypes.Product?.map((productType: string) => `
      extend type ${productType} {
        quantityInCart: Int!
        cartItemId: String
      }
    `).join('\n')

    const groups = this.config.possibleTypes.ProductGroup?.map((productGroupType: string) => `
      extend type ${productGroupType} {
        quantityInCart: Int!
        cartItemIds: [String!]!
      }
    `).join('\n')

    return gql`
      extend interface Product {
        quantityInCart: Int!
        cartItemId: String
      }
      ${products}

      extend interface ProductGroup {
        quantityInCart: Int!
        cartItemIds: [String!]!
      }
      ${groups}
    `
  }

}
