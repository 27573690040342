import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Button, Heading, Link, Paragraph, Spacer } from '@client/components/atoms/index'
import { Carousel, CarouselNavPositionEnum } from '@client/components/molecules/index'
import { CarouselFlexContainer, CarouselFlexContainerShort, FadeEffect, ResponsivePXValue } from '@client/components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .view-all {
    height: auto;
    .center-container {
      ${ResponsivePXValue('padding', '0 4px')}
    }
  }
`
const CarouselContainer = styled.div<{ $isTooShort: boolean }>`
  ${(props): CSS => (props.$isTooShort) ? CarouselFlexContainerShort : CarouselFlexContainer} 
`
const Fade = styled.div`
  ${FadeEffect('32px')}
`
const Aisle = styled.div`
  display: flex;
`
const HorizontalScrollContainer = styled.div`
  position: relative;
  width: 90vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;

  .view-all {
    align-self: center;
     ${ResponsivePXValue('margin-left', '-85px')}
    .button-title-container{
     width: max-content;
    }
  }
`

const HorizontalScrollItem = styled.div`
  flex-shrink: 0;
  height: auto;
  ${ResponsivePXValue('width', { mobile: '85vw', tablet: '90vw', desktop: '90vw' })}
`

export interface AislesProps {
  title?: string
  viewAllUrl?: string

  displayViewAllButton?: boolean
  viewAllShouldBeLink?: boolean
  children: JSX.Element | JSX.Element[]
  onViewAllClick?: (id: string) => void
  pivotId?: string
  className?: string
  aislesMaxWidth?: string
  slidesToScroll?: number
  isMiniCard?: boolean
  description?: string
  displayFade?: boolean
}

export function Aisles({ title, description, viewAllUrl, displayViewAllButton, viewAllShouldBeLink = false, children, onViewAllClick, pivotId, className, aislesMaxWidth = '1136px', slidesToScroll = 1, isMiniCard, displayFade = false }: AislesProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const navigate = useNavigate()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const maxWidth = isMobile ? '100vw' : aislesMaxWidth

  const _handleViewAllClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (pivotId) {
      onViewAllClick(pivotId)
    }
    e.preventDefault()
    navigate(viewAllUrl)
  }

  const isTooShort = Object.keys(children).length < 5

  return (
    <Container className={className}>
      <ContentContainer>
        <Heading variant='h4'>{title}</Heading>
        <If condition={displayViewAllButton}>
          <Choose>
            <When condition={viewAllShouldBeLink}>
              <Link variant='l1' href={viewAllUrl} onClick={_handleViewAllClick} decoration='underline'> view all</Link>
            </When>
            <Otherwise>
              <Button className='view-all' title='view all' color='tundora' variant='secondary' href={viewAllUrl} onClick={_handleViewAllClick} />
            </Otherwise>
          </Choose>
        </If>
      </ContentContainer>
      <Spacer universal='12px' />
      <If condition={!!description}>
        <Paragraph align='left' variant='p1'>{description}</Paragraph>
      </If>
      <Spacer universal='16px' />
      <Choose>
        <When condition={!isMiniCard}>
          <CarouselContainer className='aisles-carousel-container' $isTooShort={isTooShort}>
            <Carousel
              maxWidth={maxWidth}
              skipDesktop={true}
              slidesToScroll={slidesToScroll}
              autoPlay={false}
              navIconColor='white'
              displayNavButtons={!isMobile && !isTooShort}
              displayProgress={false}
              displayPagingInfo={false}
              loop={false}
              navPosition={CarouselNavPositionEnum.CENTER}
              navButtonLeft='-32px'
              navButtonRight='-36px'
              displayFade={displayFade}>
              {React.Children.map(children, (child, index) => {
                return <Aisle key={index}>
                  {child}
                </Aisle>
              })}
            </Carousel>
          </CarouselContainer>
        </When>
        <Otherwise>
          <HorizontalScrollContainer>
            {React.Children.map(children, (child, index) => {
              return <HorizontalScrollItem key={index}>
                {child}
              </HorizontalScrollItem>
            })}
            <If condition={displayFade}>
              <Fade />
            </If>
            <If condition={displayViewAllButton}>
              <Button className='view-all' title='view all' color='tundora' variant='secondary' href={viewAllUrl} onClick={_handleViewAllClick} />
            </If>
          </HorizontalScrollContainer>
        </Otherwise>
      </Choose>
      <Spacer mobile='12px' desktop='24px' />
    </Container>
  )
}
