import React, { Fragment, useEffect, useRef, useState } from 'react'

import Cookies from 'js-cookie'
import update from 'react-addons-update'
import OneSignal from 'react-onesignal'
import styled from 'styled-components'

import { APP_DEFAULT_STATE, AppPlugin } from '@api/local'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAddOnsRatingsLogic } from '@client/hooks/UseAddOnsRatingsLogic'
import { useMealKitRatingsLogic } from '@client/hooks/UseMealKitRatingsLogic'
import { TrackingData, useTracking } from '@client/hooks/UseTracking'
import { setDismissedMKRatingsCookie, setDismissedAddOnsRatingsCookie } from '@client/lib/RatingsHelper'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import {
  useUserDetailsQuery, useGetAppQuery, useAddGoogleClientIdMutation, useRegisterCommunicationPlatformMutation,
  LocalTrackingDataFragment, RegisteredUserDetailsFragment, UserDetailsFragment, useUserCartQuery, UserSubscriptionFragment,
} from '@hooks/api'
import { ToastHeader, Copyright, MealKitRatingModal, Notification } from '@molecules/index'
import { Footer, CartDrawer, MobileSubMenu, MobileUserAccountMenu, ApplicationNavigationBar } from '@organisms/index'
import * as Sentry from '@sentry/browser'
import { NumberOfPortionsEnum, UserCommunicationPlatformEnum, UserStatusEnum } from '@uctypes/api/globalTypes'

import { Paragraph, Spacer } from '../atoms'
import { AddOnRatingModal } from '../molecules/user/AddOnRatingModal'
import { DesktopNavigationBar } from '../organisms/navigation/DesktopNavigationBar'
import { MobileMenu } from '../organisms/navigation/MobileMenu'
import { MobileNavigationBar } from '../organisms/navigation/MobileNavigationBar'

import MainComponent from './MainComponent'
import { usePostHog } from 'posthog-js/react'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.whites.wildSand};

  .impersonation-notification {
    ${ResponsivePXValue('padding', '12px')}
    ${ResponsivePXValue('width', 'CALC(100% - 24px)')}
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 30;
`

const IID = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
`
const NavigationBar = styled.div`
  width: 100vw;
  max-width: 100vw;
`

const BottomBar = styled.div<{ $show: boolean }>`
  position: sticky;
  bottom: ${(props): number => props.$show ? 0 : 10};
  width: 100vw;
  max-width: 100vw;
  z-index: 100;
  transition: '2s all ease-in-out';
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
`

interface DefaultLayoutState {
  showBottomBar: boolean
  trackingObject: TrackingData
  addOnsRatingModal: boolean
  ratingModalOpen: boolean
}

const DEFAULT_STATE: DefaultLayoutState = {
  showBottomBar: false,
  trackingObject: null,
  addOnsRatingModal: true,
  ratingModalOpen: true,
}

export function DefaultLayout(): JSX.Element {

  const headerRef: React.RefObject<HTMLDivElement> = useRef()
  const [state, setState] = useState<DefaultLayoutState>(DEFAULT_STATE)
  const config = useConfig()
  const events = useEvents()
  const { data: userDetailsData, loading: userDetailsLoading, refetch: userDetailsRefetch } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const [addGoogleClientId] = useAddGoogleClientIdMutation()
  const [addOneSignalData] = useRegisterCommunicationPlatformMutation()
  const isRegistered = registeredUser?.__typename === 'RegisteredUser'
  const userId = userDetailsData?.currentUser?.id
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const source = isNativeApplication ? 'mobile': 'web'
  const email = userDetailsData?.currentUser?.email
  const cleanedEmail = email?.toLowerCase().includes('temp') ? '' : email
  const posthog = usePostHog()

  const {
    mealKitRatingModalOpen,
    mealKitRatingOrder,
  } = useMealKitRatingsLogic(userId, isRegistered) // Mk rating modal Hook

  const {
    addOnsRatingModalOpen,
    addOnsRatingOrder,
  } = useAddOnsRatingsLogic(userId, isRegistered) // add ons rating modal Hook

  const mealKitModalRatingOpen = mealKitRatingModalOpen && state.ratingModalOpen
  const addOnModalRatingOpen = addOnsRatingModalOpen && state.addOnsRatingModal
  const _handleMenuClick = (): void => {
    NavigationPlugin.shared().toggleMainMenu()
  }

  const _handleRatingsModalClose = (): void => {
    setState(prevState => ({ ...prevState, ratingModalOpen: false }))

    setDismissedMKRatingsCookie()

  }

  const _handleAddOnsRatingsModalClose = (): void => {
    setState(prevState => ({ ...prevState, addOnsRatingModal: false }))
    setDismissedAddOnsRatingsCookie()
  }
  const getSourceFromReferrer = (referrerUrl: string): string => {
    let referralSource = ''

    if (
      referrerUrl.includes('https://www.ucook.co.za') || referrerUrl.includes('ucook33852.activehosted.com')
    ) {
      referralSource = 'internal'
    } else if (referrerUrl.includes('app.clickup.com')) {
      referralSource = 'ClickUp'
    } else if (referrerUrl.includes('dbankcloud.com')) {
      referralSource = 'DBank Cloud'
    } else if (referrerUrl.includes('statics.teams.cdn.office.net')) {
      referralSource = 'Microsoft Teams'
    } else if (referrerUrl.includes('tagassistant.google.com')) {
      referralSource = 'Google Tag Assistant'
    } else if (referrerUrl.includes('ts.lightning.force.com')) {
      referralSource = 'Salesforce Lightning'
    } else if (
      referrerUrl.includes('ucook33852.ac-page.com') || referrerUrl.includes('ac-page.com')
    ) {
      referralSource = 'AC Page itself'
    } else if (referrerUrl.includes('oppwa.com')) {
      referralSource = 'OPPWA'
    } else if (referrerUrl.includes('gateway.zscalerthree.net')) {
      referralSource = 'Zscaler Gateway'
    } else if (config.isBrowser() && window.location.href.includes('gclid')) {
      referralSource = 'Google Ads'
    } else if (config.isBrowser() && window.location.href.includes('tmtData')) {
      referralSource = 'Admarula'
    } else if (config.isBrowser() && window.location.href.includes('fbclid')) {
      referralSource = 'Facebook Ads'
    } else {
      referralSource = 'unset'
    }

    return referralSource
  }

  NavigationPlugin.shared().useScrollPosition(
    ({ prevPos, currPos }) => {
      const shouldShow = currPos.y > prevPos.y || currPos.y === 0
      if (shouldShow !== state.showBottomBar) {
        setState((prevState) => update(prevState, {
          showBottomBar: { $set: shouldShow },
        }))
      }
    },
    [state.showBottomBar],
    300,
  )
  const _handleOneSignalInsert = async (input: {deviceId: string, platformId: string, platformType: UserCommunicationPlatformEnum}): Promise<void> => {
    try {
      await addOneSignalData({
        variables: input,
      })
      userDetailsRefetch()
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  useEffect(() => {
    if ((registeredUser?.status===UserStatusEnum.ACTIVE)) {

      OneSignal.Slidedown.promptPush()

      const permissionChangeListener = (permission: boolean) => {
        if (permission) {
          try {
            OneSignal.login(userDetailsData?.currentUser?.id)
            AppPlugin.shared().setIsOneSignalLoggedIn(true)
          } catch (e) {
            console.log(e)
          }
        }
      }

      OneSignal.Notifications.addEventListener('permissionChange', permissionChangeListener)

      const userCommunicationData = registeredUser?.currentDevice?.userCommunicationPlatformData
      const communicationData = Array(userCommunicationData)[0]
      let isUserCommunicationPlatformRegistered = false
      for (let i = 0; i < Object.keys(communicationData)?.length; i++) {
        if (Object.values(communicationData)[i].platformType === UserCommunicationPlatformEnum.ONE_SIGNAL) {
          isUserCommunicationPlatformRegistered = true
          break
        }
      }

      const isOnesignalSubscribed = OneSignal.User.PushSubscription.optedIn

      if (!isUserCommunicationPlatformRegistered && isOnesignalSubscribed && !appData?.app?.isOneSignalLoggedIn) {
        _handleOneSignalInsert({
          deviceId: registeredUser.currentDevice.id,
          platformId: registeredUser.id,
          platformType: UserCommunicationPlatformEnum.ONE_SIGNAL,
        })
      } else {
        OneSignal.login(
          userDetailsData?.currentUser?.id,
        )
        AppPlugin.shared().setIsOneSignalLoggedIn(true)
      }

      events.hasLoggedIn(userDetailsData?.currentUser?.id, 'Automatic', source)

      if (!isNativeApplication) {

        if (!isUserCommunicationPlatformRegistered && isOnesignalSubscribed && !appData?.app?.isOneSignalLoggedIn) {
          _handleOneSignalInsert({
            deviceId: registeredUser.currentDevice.id,
            platformId: registeredUser.id,
            platformType: UserCommunicationPlatformEnum.ONE_SIGNAL,
          })
          OneSignal.login(
            userDetailsData?.currentUser?.id,
          )
          AppPlugin.shared().setIsOneSignalLoggedIn(true)
        }
      }
    }
  }, [])

  NavigationPlugin.shared().useScrollPosition(
    ({ prevPos, currPos }) => {
      const shouldShow = currPos.y > prevPos.y || currPos.y === 0
      if (shouldShow !== state.showBottomBar) {
        setState((prevState) => update(prevState, {
          showBottomBar: { $set: shouldShow },
        }))
      }
    },
    [state.showBottomBar],
    300,
  )

  useEffect(() => {
    if (!userDetailsLoading && userDetailsData.currentUser) {

      setTimeout(() => {
        events.hasAddedUserId(userDetailsData?.currentUser?.id)
      }, 5000)
      posthog.identify(userDetailsData.currentUser.id, {
        email: userDetailsData.currentUser.email, 
        name: userDetailsData.currentUser.firstName+' '+userDetailsData.currentUser.lastName
      })

      const googleClientId = Cookies.get('_ga')?.substring(6)
      if (googleClientId !== registeredUser.googleClientId && !!googleClientId) {
        addGoogleClientId({
          variables: { googleClientId },
        })
      }
      const googleSessionId = Number(Cookies.get('_ga_0XYH77FZW0')?.substring(6, 16))
      const facebookBrowserId = Cookies.get('_fbp') || ''
      const currentUrl = config.isBrowser() && window.location.href

      let admarulaUTMTracker = null
      let facebookClickId = null
      let instagramClickId = null
      let googleClickId = null
      let tikTokClickId = null
      let discountCode = null

      const searchParams = new URLSearchParams(new URL(currentUrl).search)
      googleClickId = searchParams.get('gclid') || ''
      admarulaUTMTracker = searchParams.get('tmtData') || ''
      facebookClickId = searchParams.get('fbclid') || ''
      instagramClickId = searchParams.get('igclid') || ''
      tikTokClickId = searchParams.get('ttclid') || ''
      discountCode = searchParams.get('discountCode') || ''

      let referrerUrl = document.referrer
      let source = getSourceFromReferrer(referrerUrl)

      const trackingData: TrackingData = {
        googleSessionId: isNaN(googleSessionId) ? 0 : googleSessionId,
        googleClickId,
        facebookBrowserId,
        facebookClickId,
        instagramClickId,
        admarulaUTMTracker,
        tikTokClickId,
        referrerUrl,
        source,
      }

      if (!appData.app.hasInitiallySetTrackingData) {

        const trackingDataObject: LocalTrackingDataFragment = {
          ...trackingData,
          __typename: 'LocalTrackingData',
        }
        AppPlugin.shared().setTrackingData(trackingDataObject)
        AppPlugin.shared().setHasInitiallySetTrackingData(true)

      } else {

        const localTrackingData = appData.app.trackingData

        googleClickId = localTrackingData.googleClickId
        admarulaUTMTracker = localTrackingData.admarulaUTMTracker
        facebookClickId = localTrackingData.facebookClickId
        instagramClickId = localTrackingData.instagramClickId
        tikTokClickId = localTrackingData.tikTokClickId
        referrerUrl = localTrackingData.referrerUrl
        source = localTrackingData.source

      }

      if (discountCode) {
        AppPlugin.shared().setUrlDiscount(discountCode)
      }

      setState((prevState) => update(prevState, {
        trackingObject: { $set: trackingData },
      }))

    }

  }, [userDetailsData?.currentUser?.id])

  useEffect(() => {
    const defaultPortions = subscription?.numberOfPortions
    const cookiePortion = Cookies.get('defaultPortion') as unknown as NumberOfPortionsEnum

    if (defaultPortions) {
      AppPlugin.shared().setDefaultPortions(defaultPortions)
      Cookies.set('defaultPortion', String(defaultPortions), { expires: 365 })
    } else if (cookiePortion) {
      AppPlugin.shared().setDefaultPortions(cookiePortion)
    }

    if (cleanedEmail) {
      Sentry.setUser({ email: userDetailsData?.currentUser?.email })
    } else {
      Sentry.setUser(null)
    }
  }, [userDetailsData])

  useTracking(state.trackingObject)
  return (

    <Fragment>
      <Container>
        <MealKitRatingModal
          open={mealKitModalRatingOpen}
          onClose={_handleRatingsModalClose}
          ratingOrder={mealKitRatingOrder} />
        <AddOnRatingModal
          open={addOnModalRatingOpen}
          onClose={_handleAddOnsRatingsModalClose}
          ratingOrder={addOnsRatingOrder} />
        <If condition={!!registeredUser}>
          <IID>
            <Paragraph className='iie'>{cleanedEmail}</Paragraph>
            <Paragraph className='iim'>{userDetailsData?.currentUser?.phone}</Paragraph>
          </IID>
        </If>
        <Choose>
          <When condition={!isNativeApplication}>
            <Header ref={headerRef}>
              <ToastHeader />
              <NavigationBar>
                <DesktopNavigationBar />
                <MobileNavigationBar onIconClick={_handleMenuClick} />
              </NavigationBar>
            </Header>
          </When>
          <Otherwise>
            <Header>
              <ToastHeader />
            </Header>
          </Otherwise>
        </Choose>
        <If condition={!!(userDetailsData?.currentUser as RegisteredUserDetailsFragment)?.impersonatedBy}>
          <Notification
            className='impersonation-notification'
            align='center'
            text={`Hi ${(userDetailsData?.currentUser as RegisteredUserDetailsFragment).impersonatedBy.firstName} you are currently impersonating ${userDetailsData?.currentUser.firstName}, please log out when you are done to end the impersonation`} />
        </If>
        <Content>
          <MainComponent />
          <If condition={!isNativeApplication}>
            <Footer />
          </If>
          <Copyright />
          <MobileMenu />
          <MobileSubMenu />
          <MobileUserAccountMenu />
          <CartDrawer />
        </Content>
        <If condition={isNativeApplication}>
          <BottomBar $show={true}>
            <Spacer universal='8px' />
            <ApplicationNavigationBar />
          </BottomBar>
        </If>
      </Container>
    </Fragment>
  )
}
